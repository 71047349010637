import React, { Component } from 'react';
import AppBarplain from "../../../Atoms/AppbarPlain/Component";
import GreySpanButton from "../../../Atoms/GreySpanButton/Component";
import OrangeSpanButton from "../../../Atoms/OrangeSpanButton/Component";
import NeedHelpModal from "./NeedHelp/Component";
import {Link, withRouter} from "react-router-dom";
import {Container, Row, Col} from 'react-bootstrap';
import { Button } from '@material-ui/core';

import {ReactComponent as PhoneImg} from "../../../images/phone.svg"
import {ReactComponent as StoreImg} from "../../../images/store.svg"
import {ReactComponent as GreenTick} from "../../../images/greentick.svg";
import {ReactComponent as One} from "../../../images/Dots/one.svg";
import {ReactComponent as Two} from "../../../images/Dots/two.svg";
import {ReactComponent as Three} from "../../../images/Dots/three.svg";
import {ReactComponent as Four} from "../../../images/Dots/four.svg";
import {ReactComponent as Five} from "../../../images/Dots/five.svg";
import * as AppConstants  from '../../../utils/AppConstants';

import './styles.css';
import axios from "axios";
import firebase from './../../../firebase'
import ls from 'local-storage'
import appAnalytics from '../../../AppAnalytics';

class SendingPage extends Component {

    constructor(props) {
        super(props);
        console.log("default props from last screen "+ ls.get("name"));
        this.state = {
            storeId: ls.get("name"),
            address: this.props.cookies.get('address'),
            email:  this.props.cookies.get("email"),
            product: ls.get("product"),
            value: this.props.cookies.get('value'),
            receiptType : this.props.cookies.get("receiptType"),
            isCartFull: false,
            transactionStatus: false,
            isReceiptPage: false,
            isDispensePage: false,
            error: false,
            isShowModal: false,
            isDataSendToFirebase: ls.get("dataSent")
        };
        
        this.db = firebase.firestore();
    }

    unsubscribe;
    transactionsubscribe;
    receiptSubscribe;
    dispenseSubscribe;
    cpunsubscribe;
    cancelTokenSource = axios.CancelToken.source();


    setModalShow(isBool){
        this.setState({isShowModal:isBool})
    }

    componentDidMount() {
        console.log("---componentDidMount--")
        appAnalytics.customLogEvent("Sending Page",{
            content_type: 'sending_page',
            content_id: 'sending_page',
            items: []
        })
       
        
        

        
    }
    componentWillMount(){
        console.log("---componentWillMount--")
        this.listenToTransactionStatus();
        this.listenToCartStatus();
        this.listenToCPQueueStatus();
        this.sendDataToEstation();
    }
    

    componentWillUnmount(){
        console.log("---componentWillUnmount--")
        this.unsubscribe();
        this.transactionsubscribe();
        this.receiptSubscribe();
        this.dispenseSubscribe();
        this.cpunsubscribe()
        this.cancelTokenSource.cancel()
    }

    sendDataToEstation(){
        const {storeId,isDataSendToFirebase} = this.state;
        if (storeId && !isDataSendToFirebase) {
            this.db.collection(AppConstants.FIREBASE_BASE_KEY).
            doc(storeId).collection(AppConstants.FIREBASE_STORE_STATUS_KEY).
            doc(AppConstants.FIREBASE_STORE_CPQUEUE_KEY).set({status: true})
            this.getDataFromFireStore();
            ls.set("dataSent",true)
        } else {
            console.log("--no store Id---")
            this.setState({"transactionStatus":true})
        }
    }

    getDataFromFireStore(){
        console.log("--storeId---"+this.state.storeId);
        var storeDoc = this.db.collection(AppConstants.FIREBASE_BASE_KEY).doc(this.state.storeId);

        storeDoc.get().then((doc) => {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                this.addDataToFireStore(doc.data());
            } else {
                console.log("No Such Document");
                this.addDataToFireStore(doc.data());
            }
        })
    }

    addDataToFireStore(data){
        
        if(data && data.queue && data.queue.length > 0 ){
            console.log("empty data");
            return;
        } else {
            console.log("create first time data");
            data = {
                "queue":[{
                    cart: [{
                        product_id: this.state.product.prodId ? this.state.product.prodId: '',
                          product: this.state.product,
                          quantity: 1
                    }],
                    receipt_id: this.state.email && this.state.email.length > 0 ? this.state.email : "" ,
                    receipt_type: this.state.email && this.state.email.length > 0 ? this.state.receiptType : 'no-print'
                }]
            }
        }
        //this.db.collection(AppConstants.FIREBASE_BASE_KEY).doc("EQT91wKjJxvigeSHRBb9").set(data)
        ls.set("dataSent",true)
        this.db.collection(AppConstants.FIREBASE_BASE_KEY).doc(this.state.storeId).set(data);
        
    }
    resetState(){
        this.setState({"product":undefined})
    }

    isCartFull = false;
    tIndex = 0;

    listenToTransactionStatus(){
        let storeId =  this.state.storeId;

        this.transactionsubscribe = this.db.collection(AppConstants.FIREBASE_BASE_KEY).doc(storeId).collection(AppConstants.FIREBASE_STORE_STATUS_KEY).doc(AppConstants.FIREBASE_STORE_TRANSACTION_KEY).onSnapshot(doc => {
            console.log("PK---transaction--status-- : "+doc.data());
            if(this.tIndex >= 1|| true){
                let status = doc.data();
               
                this.setState({transactionStatus: status["status"]});
                if(status.status){
                    this.transactionsubscribe()
                }
            }

            this.tIndex = this.tIndex + 1;
        })
        this.listenToReceiptPageStatus();
    }
    listenToReceiptPageStatus(){
        let storeId =  this.state.storeId;

        this.receiptSubscribe = this.db.collection(AppConstants.FIREBASE_BASE_KEY).doc(storeId).collection(AppConstants.FIREBASE_STORE_RECEIPT_PAGE_KEY).doc(AppConstants.FIREBASE_STORE_TRANSACTION_KEY).onSnapshot(doc => {
            console.log("PK---receipt--status-- : "+doc.data());
            
                let status = doc.data();
                if(status && this.state.isReceiptPage){
                    this.setState({
                        isReceiptPage: status["status"]
                    });
                    this.receiptSubscribe()
                }
        })
        this.dispenseSubscribe = this.db.collection(AppConstants.FIREBASE_BASE_KEY).doc(storeId).collection(AppConstants.FIREBASE_STORE_DISPENSE_PAGE_KEY).doc(AppConstants.FIREBASE_STORE_TRANSACTION_KEY).onSnapshot(doc => {
            console.log("PK---dispense--status-- : "+doc.data());
            
                let status = doc.data();
               if(status && this.state.isDispensePage){
                this.setState({
                    isDispensePage: status["status"]
                });
                this.dispenseSubscribe();
                this.updateTransactionStatusUI(status);
               }
        })
    }

    cIndex = 0;

    listenToCartStatus(){
        let storeId =  this.state.storeId;
        this.unsubscribe = this.db.collection(AppConstants.FIREBASE_BASE_KEY).doc(storeId).collection(AppConstants.FIREBASE_STORE_STATUS_KEY).doc(AppConstants.FIREBASE_STORE_CART_KEY).onSnapshot(doc => {
            console.log("PK---cart--status-- : "+doc.data());
            if(this.cIndex >= 1){
                this.isCartFull = doc.data()["status"];
                this.setState({
                    isCartFull: this.isCartFull
                });

                if(this.state.storeId){
                   // this.unsubscribe();
                    //this.db.collection(AppConstants.FIREBASE_BASE_KEY).doc(storeId).collection(AppConstants.FIREBASE_STORE_STATUS_KEY).doc(AppConstants.FIREBASE_STORE_CART_KEY).set({"status":false});
                }
            }
           
            this.cIndex = this.cIndex + 1;
        })
    }

    listenToCPQueueStatus(){
        let storeId =  this.state.storeId;
        this.cpunsubscribe = this.db.collection(AppConstants.FIREBASE_BASE_KEY).doc(storeId).onSnapshot(doc => {
            console.log("PK---cart--status-- : "+doc.data());
            if(this.state.isDataSendToFirebase){
                let queueStatus = doc.data().queue;
                this.setState({
                    transactionStatus: (queueStatus.length == 0)
                }, () => {
                    if(queueStatus.length == 0){
                        this.resetCookies()
                    }
                });
            }
        })
    }

    resetCookies(){
        this.props.history.push("/?id="+ls.get("name"));
        this.props.cookies.set("email","") ;
        this.props.cookies.set("product","");
        this.props.cookies.get('value',"");
        console.log("---go back--");
    }

    updateTransactionStatusUI(status){
       if(status){
        console.log("==="+status.status);
       
        let data = {
            "queue":[]
        };

        if(this.state.storeId){
            this.db.collection(AppConstants.FIREBASE_BASE_KEY).doc(this.state.storeId).set(data);
            this.resetCookies();
        }
    }
}

render() {
    return (
        <div>
            <AppBarplain/>
            {
                this.isCartFull ?
                    <div>
                        <h1>Oops!</h1>
                        <div>Cart Full</div>
                        <div className={"btn_div"}>
                            <Link to={"/?id="+ls.get("name")} className={"finish_btn"}>
                                <OrangeSpanButton>Go back & start over</OrangeSpanButton>
                            </Link>
                        </div>
                    </div>
                :
                    <div>
                        <div className={"main"}>
                            <h1>Sending order {this.state.value} to store</h1>
                            <div className={"instructions_div"}>
                                <span>Follow the instructions on the store to complete your purchase.</span>
                            </div>
                        </div>

                        <div className={"image_div"}>
                            <PhoneImg/>
                            {!this.state.transactionStatus ?
                                <>
                                    <One className={"bottom dot"}/>
                                    <Two className={"mid dot"}/>
                                    <Three className={"top dot"}/>
                                    <Four className={"mid dot"}/>
                                    <Five className={"bottom dot"}/>
                                </>
                                :
                                <GreenTick className={"greentick"}/>
                            }
                            <StoreImg/>
                        </div>

                        {!this.state.transactionStatus ?
                            <div className={"sale_info"}>
                                <span>{this.state.value}</span>

                                <Container fluid className={'sending_order'}>
                                    <Row noGutters={true} className={"main-row"} >
                                        <Col><p className={"title"}>Sub Total</p></Col>
                                        <Col><p className={"dollars"}>${this.state.product.price}</p></Col>
                                    </Row>

                                    <Row noGutters={true} className={"main-row"} >
                                        <Col><p className={"title"}>Tax</p></Col>
                                        <Col><p className={"dollars"}>-</p></Col>
                                    </Row>

                                    <Row noGutters={true} className={"divider-row"} >
                                        <Col><hr/></Col>
                                    </Row>

                                    <Row noGutters={true} className={"main-row"} >
                                        <Col><p className={"title"}>Total</p></Col>
                                        <Col><p className={"dollars"}>{this.state.product.price} + Taxes</p></Col>
                                    </Row>
                                </Container>
                            </div>
                        :
                            <div className={"order_received"}>
                                <p>Order successfully received at:</p>

                                <p
                                    className={"address"}
                                    dangerouslySetInnerHTML={{__html: ls.address}}
                                />

                                <Button
                                    onClick={() => this.setModalShow(true)}
                                    className={"modal_btn"}>
                                    Need help?
                                </Button>

                                <NeedHelpModal
                                    modalIsopen={this.state.isShowModal}
                                    closeModal={() => this.setModalShow(false)}
                                />
                            </div>
                        }
                        {
                           this.state.transactionStatus && !this.state.isReceiptPage && !this.state.isDispensePage? <div>Loading Receipt..</div>:null
                        }
                        {
                           this.state.transactionStatus && this.state.isReceiptPage && !this.state.isDispensePage? <div>Dispensing Products..</div>:null
                        }

                        <div className={"btn_div"}>
                            {
                                !this.state.transactionStatus ?
                                    <div className={"finish_btn"}>
                                        <GreySpanButton>FINISHED & START OVER</GreySpanButton>
                                    </div>
                                :
                                    <Link to={"/?id="+ls.get("name")} className={"finish_btn"}>
                                        <OrangeSpanButton>FINISHED & START OVER</OrangeSpanButton>
                                    </Link>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default withRouter(SendingPage);