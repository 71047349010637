import React from "react";
import './styles.css';

// class EmailInput extends React.Component{
//     constructor(props) {
//         super(props);
//     }
//
//     render() {

const EmailInput = (props) => {
        return(
          <div>
              <input
                  className={`email_inp ${props.className}`}
                  placeholder={props.placeholder}
                  onChange={props.onChange}
                  value={props.value}
                  type="email"
              />
          </div>
        );
    // }
}

export default EmailInput;
