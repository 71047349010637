import React, {useState, useEffect, useRef} from 'react';
import './styles.css';
import Logo from "../../../images/logo.png";
import FindNumberModal from "./FindNumber/Component";
import {withRouter} from "react-router-dom";
import {Redirect} from "react-router";
import axios from 'axios';
import {connect} from "react-redux";
import OrangeSpanButton from "../../../Atoms/OrangeSpanButton/Component";
import { Button } from '@material-ui/core';
import ls from 'local-storage'
import appAnalytics from '../../../AppAnalytics';

const StoreChoose = (props) => {
    let query = props.location.search.length > 0 ? props.location.search.split("?id=")[1] : '';
    const [modalIsOpen, setModalOpen] = useState(false);
    const [inputValue, setInputValue] = useState(query);
    const [errorHid, setErrorHid] = useState(true);
    const [redirect, setRedirect] = useState(query ? true: false);
    const textField = useRef(null);
    const cancelTokenSource = axios.CancelToken.source();

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            validate(inputValue);
        }
    };

    useEffect(() => {
        appAnalytics.customLogEvent("Store Choose",{
            content_type: 'select_store',
            content_id: 'select_store',
            items: []
        })
        if(query && query.length > 0 ){
            props.cookies.set("name", inputValue.toUpperCase(), {path: "/"});
            ls.set("name",query);
            validate(query)
        }else{
            textField.current.focus();
        }
        return () => {
            cancelTokenSource.cancel()
        }
    }, []);

    async function validate(input) {
        if (input === '') {
            setErrorHid(false);
            return;
        }
        input = input.toUpperCase();
        await axios.get(process.env.REACT_APP_API_ENDPOINT2,
        {
            params: {
                id: input
            }
        }).then(function (response) {
            if (response.data === false) {
                setErrorHid(false);
                setRedirect(false);
            } else {
                props.cookies.set("name", inputValue.toUpperCase(), {path: "/"});
                props.cookies.set("loading", true);
                ls.set("products",[]);
                ls.set("name",inputValue.toUpperCase());
                props.cookies.set("isSplashLoaded", false);
                props.cookies.set('email', '');
                setErrorHid(true);
                setRedirect(true);
            }
        });
    }

    return (
        redirect ? <Redirect push to={{pathname: '/v1/product-page', storeId: inputValue.toUpperCase()}}/> :
            <div style={divStyle}>
                <div className={"client_logo"}>
                    <img
                        src={Logo}
                    />
                </div>
                <div>
                    <h3>Contactless Shopping</h3>
                    <h2>Enter the Store ID to start shopping</h2>
                </div>
                <div className={"error_div"}>
                    <input
                        type='text'
                        id='titleField'
                        value={inputValue}
                        onChange={(event) => {
                            setInputValue(event.target.value);
                        }}
                        className={errorHid ? 'input_field' : 'input_field error'}
                        placeholder={"e.g CVSUS0011"}
                        onKeyDown={onKeyDown}
                        ref={textField}
                    />
                    <span
                        className={"error_msg"}
                        hidden={errorHid}
                    >Incorrect Store ID</span>
                </div>
                <div>
                    <Button
                        className={"modal_btn"}
                        onClick={() => setModalOpen(true)}
                        id={"modalbtn"}>
                        Can't find the Store ID?
                    </Button>

                    <FindNumberModal
                        modalIsopen={modalIsOpen}
                        closeModal={() => setModalOpen(false)}
                    />
                </div>
                <div className={"search_btn_div"}>
                    <OrangeSpanButton className={"search_btn"} onClick={
                        async () => {
                            await validate(inputValue);
                        }
                    }>Submit</OrangeSpanButton>
                </div>
            </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return ({state: state, cookies: ownProps.cookies});
};

// export default StoreChoose;
export default connect(mapStateToProps, null)(withRouter(StoreChoose));

const divStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    margin: '20% 20px 0'
};
