import React, {useEffect, useState} from "react";
import AppBarplain from "../../../Atoms/AppbarPlain/Component";
import WithoutReceipt from "./Components/WithoutReceipt/Component";
import WithReceipt from "./Components/WithReceipt/Component";
import EmailInput from "../../../Atoms/EmailInput/Component";
import Checkbox from "../../../Atoms/Checkbox/Component";
import PrivacyPolicyModal from "./PrivacyPolicy/Component";
import { Button } from '@material-ui/core';
import "./styles.css"
import ProductTile from "../ProductPage/ProductTile";
import classNames from 'classnames';
import ls from 'local-storage'
import appAnalytics from "../../../AppAnalytics";

function CartPage(props) {
    const [emailValue, setEmailValue] = useState('');
    const [receipt, setReceipt] = useState(false);
    const [checked, setChecked] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [modalIsOpen, setModalOpen] = useState(false);

    function onClick() {
        setSubmit(true);
        props.cookies.cookies.email = emailValue;
    }

    function changeWithReceipt(event) {
        setEmailValue(event.target.value);
        props.cookies.set("email", event.target.value);
        props.cookies.set("receiptType", "email");
        setReceipt(true);
        setSubmit(false);
    }

    function changeWithoutReceipt(event) {
        setEmailValue('');
        setReceipt(false);
    }

    function validateEmail(value) {
        const emailRegExp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        return emailRegExp.test(value);
    }

    function Checkout(props) {
        if (!props.receipt && props.cookies.cookies.email == '') {
            appAnalytics.customLogEvent("Receipt Page",{
                content_type: 'WithOutReceipt',
                content_id: 'WithOutReceipt',
                items: []
            })
            return <WithoutReceipt onChange={changeWithReceipt} submit={onClick} checked={true} val={emailValue} cookies={props.cookies}/>
        } else {
            appAnalytics.customLogEvent("Receipt Page",{
                content_type: 'WithReceipt',
                content_id: 'WithReceipt',
                items: []
            })
            return <WithReceipt onClick={changeWithoutReceipt} submit={onClick} checked={checked} val={emailValue} cookies={props.cookies}/>
        }
    }

    const inputErrorCl = classNames({
        'error': !(!submit || !receipt || (receipt && (validateEmail(emailValue))))
    });

    const checkErrorCl = classNames({
        'error': !(!submit || checked)
    });
    useEffect(() => {
        appAnalytics.customLogEvent("Receipt Page",{
            content_type: 'receipt_page',
            content_id: 'receipt_page',
            items: []
        })
    })

    return (
        <div>
            <AppBarplain/>
            <div>
            <div className={"receipt_div"}>
                <ProductTile product={ls.get("product")}/>
                <div className={"content"}>
                    <div>Your order will be sent to STORE {ls.get("name")}<br/> to complete checkout</div>{}

                    <div>
                        <p className={"email_bod"}>Enter your email for a copy of your receipt</p>
                        <EmailInput
                            id={"emailFi"}
                            placeholder={"Email Address"}
                            onChange={changeWithReceipt}
                            value={props.cookies.cookies.email || emailValue}
                            className={inputErrorCl}
                        />
                        <div className={"error_message"}>
                            <span hidden={!submit || !receipt || (receipt && (validateEmail(emailValue)))}>Please enter a valid email address</span>
                        </div>
                    </div>
                    <div style={checkDivStyle}>
                        <Checkbox
                            checked={checked}
                            onChange={() => setChecked(!checked)}
                            className={checkErrorCl}
                        />
                        <p className={'agree'}>
                            I agree to the
                            <Button
                                className={"modal_btn"}
                                onClick={() => setModalOpen(true)}
                                id={"modalbtn"}>
                                Swyft Privacy Policy
                            </Button>

                            <PrivacyPolicyModal
                                modalIsopen={modalIsOpen}
                                closeModal={() => setModalOpen(false)}
                            />
                        </p>
                    </div>
                    <div className={"error_message"}>
                        <span hidden={(!submit || checked)}>Please agree to the Swyft Privacy Policy</span>
                    </div>

                    <Checkout receipt={receipt} checked={checked} cookies = {props.cookies}/>
                </div>
            </div>
        </div>
        </div>
    );

}

export default CartPage;

const checkDivStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "left",
    width: "90%",
    margin: "0 auto",
    height: "20px"
};