import React from "react";
import AppBarplain from "../../../Atoms/AppbarPlain/Component";
import "./ProdDetailStyles.css";
import {Link} from "react-router-dom";
import OrangeSpanButton from "../../../Atoms/OrangeSpanButton/Component";
import PlaceHolder from "../../../images/placeholder.svg";
import Carousel from "react-bootstrap/Carousel";
import ls from 'local-storage'
import appAnalytics from "../../../AppAnalytics";


const MyCarousel = ({ items }) => (
    <Carousel controls={false} interval={null} slide={true}>
        {items.map((item, i) =>
            <Carousel.Item key={i}>
                <img src={item.url} alt='alt text'/>
            </Carousel.Item>
        )}
    </Carousel>
)

class ProductDetail extends React.Component {
  componentDidMount(){
      appAnalytics.customLogEvent("Product Detail Page",{
        content_type: 'product_detail_page',
        content_id: 'product_detail_page',
        items: [ls.get("product")]
    })
  }
    render() {
        var obj = ls.get("product") ? ls.get("product") : this.props.cookies.get("product");
        return(
            <div className="prod_detail">
                <AppBarplain/>

                {obj && obj.displayAttrs && obj.displayAttrs.en.multiViews ? (
                    <MyCarousel items={obj.displayAttrs.en.multiViews} />): <img
                        className={"image_tile"}
                        src={obj && obj.displayAttrs && obj.displayAttrs.en.productImage ?
                            obj.displayAttrs.en.productImage.url
                            :
                            PlaceHolder
                        }
                        alt={obj.name}
                    />
                }

                <div className={"prod_info"}>
                    <div className={"prod_details"}>
                        <span className={"prod_name"}
                            dangerouslySetInnerHTML={{__html: obj.displayAttrs.en.displayName}}></span>
                        {obj.displayAttrs.en.tagline1 &&
                            <span className={"prod_tagline"}
                                dangerouslySetInnerHTML={{__html: obj.displayAttrs.en.tagline1}}></span>
                        }
                    </div>
                    <div className={"prod_price_div"}>
                        <span className={"prod_price"}>${obj.price}</span>
                    </div>
                </div>

                <div className={"prod_desc"}>
                    <span
                        dangerouslySetInnerHTML={{__html: obj.displayAttrs.en.prodDescSec1}}></span>
                </div>

                <div className={"buy_btn"}>
                    <Link to={{pathname: "/v1/your-order", data: {product: obj, storeId: ls.get("name")}}}>
                        <OrangeSpanButton>BUY NOW</OrangeSpanButton>
                    </Link>
                </div>
            </div>
        );
}

}

export default ProductDetail;
