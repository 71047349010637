export const FIREBASE_BASE_KEY = 'contactless';
export const FIREBASE_STORE_STATUS_KEY = 'status';
export const FIREBASE_STORE_TRANSACTION_KEY = 'transaction'
export const FIREBASE_STORE_RECEIPT_PAGE_KEY = 'receipt_page'
export const FIREBASE_STORE_DISPENSE_PAGE_KEY = 'dispense_page'
export const FIREBASE_STORE_CPQUEUE_KEY = 'cpQueue'

export const FIREBASE_STORE_CART_KEY = 'isCartFull'
// export const BASE_API_URL = "https://us-central1-zoom-shops-qa.cloudfunctions.net/contactless_payment_api/api/stores/"
// export const BASE_API_URL2 = "https://us-central1-zoom-shops-qa.cloudfunctions.net/contactless_payment_api/api/validate"
// export const BASE_DEV_URL = "https://us-central1-zoom-shops-dev.cloudfunctions.net/contactless_payment_api/api/stores/"