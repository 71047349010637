import React  from "react";
import {Link} from "react-router-dom";
import "./ProductTileStyle.css";
import PlaceHolder from "../../../../images/placeholder.svg";
import ls from 'local-storage'


function ProductTile(props) {
    const getProd = () => {
        props.cookies.set("product", props.product, {path: "/"});
        ls.set("product",props.product)
        
    };

    return (
        <Link to={{pathname: "/v1/product-detail", data: {product: props.product, storeId: props.storeId}}}>
        <button className={"product_tile"} onClick={getProd}>
            <div className={"main_div"}>
                <div className={"prod_info"}>
                    <div className={"prod_image_div"}>
                        <img
                            className={"prod_image"}
                            src={props.product.displayAttrs && props.product.displayAttrs.en && props.product.displayAttrs.en.productImage  ?
                                props.product.displayAttrs.en.productImage.url
                                :
                                PlaceHolder
                            }
                            alt={props.product.name}
                        />

                    </div>
                    <div className={"prod_details"}>
                        <span className={"prod_name"}
                            dangerouslySetInnerHTML={{__html: props.product.displayAttrs.en.displayName}}></span>
                        {props.product.displayAttrs.en.tagline1 &&
                            <span className={"prod_tagline"}
                                dangerouslySetInnerHTML={{__html: props.product.displayAttrs.en.tagline1}}></span>
                        }
                    </div>
                    <div className={"prod_price_div"}>
                        <span className={"prod_price"}>${props.product.price}</span>
                    </div>
                </div>
            </div>
        </button>
        </Link>
    );
}

export default ProductTile;

