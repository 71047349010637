import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const OrCheckbox = withStyles({
    root: {
        color: "#000000",
        '&$checked': {
            color: "#3CBDE2",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CustCheckbox = (props) => {
    return (
        <div>
            <FormControlLabel
                control={
                    <OrCheckbox
                        icon={<CheckBoxOutlineBlankIcon
                            fontSize="small"
                            className={props.className}
                        />}
                        checkedIcon={
                            <CheckBoxIcon
                                fontSize="small"
                                className={props.className}
                            />
                        }
                        name="checkedI"
                        onChange={props.onChange}
                        checked={props.checked}
                    />
                }
            />
        </div>
    );
}

export default CustCheckbox;
