import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import React from "react";
import Logo from "../../images/logo.png";
import {Link} from "react-router-dom";

class AppBarplain extends React.Component {
    render() {
        return(
            <div>
                <AppBar position="static" color={'transparent'}>
                    <Toolbar>
                        <div className="header_logo">
                            <Link to={"/"}>
                                <img
                                    src={Logo}
                                />
                            </Link>
                        </div>
                        <Typography variant="h6" style={title}>
                            {this.props.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {/*TODO: display item selected*/}
            </div>
        );
    }
}

export default AppBarplain;

const title = {
    position: "right",
    color: "black",
    fontSize: "16px",
}
