import styled from "styled-components";

const GreySpanButton = styled.button`
    background-color: #979797;
    color: white;
    height: 40px;
    width: 100%;
    align-items: center;
    border: none;
`;

// class GreySpanButton extends React.Component{
//     render() {
//         return (
//             // <div style={divStyle}>
//                 <Button>{this.props.label}</Button>
//             // </div>
//         );
//     }
// }

export default GreySpanButton;
