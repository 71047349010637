import axios from "axios";
import React from "react";
import ProductTile from "./ProductTile";
import SplashPage from "./SplashPage";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {Link} from "react-router-dom";
import Logo from "../../../images/logo.png"
import LogoSwyft from "../../../images/logo-swyft.png"
import {Dropdown, ButtonGroup, Button} from "react-bootstrap";
import { withStyles } from '@material-ui/styles';
import "./ProductPageStyle.css";
import firebase from './../../../firebase'
import * as AppConstants  from '../../../utils/AppConstants';
import ls from 'local-storage'
import appAnalytics from "../../../AppAnalytics";

    const useStyles = (theme) => ({
        root: {
            flexGrow: 1
        },
        title: {
            flexGrow: 1,
            color: "#3CBDE2"
        },
        appbar: {
            color: "white"
        }
    });

class GetProducts extends React.Component {

    constructor(props) {
        super(props);
        
        if(window.location.pathname != '/v1/product-page'){
            ls.set('name','')
            ls.set('products',[])
        }
        let prods = ls.get("products");
        this.state = {
            products:  prods ? prods : [],
            storeId: ls.get('name') ? ls.get('name').toUpperCase(): window.location.pathname.toUpperCase().replace("/",""),
            address: "",
            loading: true,
            namespace: ""
        };

        this.db = firebase.firestore();
        this.loadSplashScreen = this.loadSplashScreen.bind(this);
        appAnalytics.customLogEvent("Products Page",{
            content_type: 'products_page',
            content_id: 'products_page',
            items: []
        })
    }

    cancelTokenSource = axios.CancelToken.source();

    loadSplashScreen(newState) {
        this.setState({
            loading: newState
        });
    }

    componentDidMount() {
        let data = {
            "queue":[]
        };
        //this.setState({products: ls.get("products") ? ls.get("products") :[] })
        this.db.collection(AppConstants.FIREBASE_BASE_KEY).doc(this.state.storeId).set(data);
        ls.set("dataSent",false);
        var s = '';
        let headers = {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          };

        axios.get(process.env.REACT_APP_API_ENDPOINT + this.state.storeId, {'headers':headers})
        .then(response => {
            let namespace = response.data.namespace;
            ls.set("namespace",namespace);
            this.setState({namespace: namespace});
            this.loadProducts();
        })
        .catch(function (error) {
            console.log("Error");
        });

        if(this.state.products && this.state.products.length > 0 ){
            //return
        }
    }

    loadProducts = () => {
        let products = [];
        let headers = {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          };
          ls.set("name",this.state.storeId)
        axios.get(process.env.REACT_APP_API_ENDPOINT + this.state.storeId + "/products",{'headers':headers})
        .then(response => {
            products = response.data;
            this.updateProducts(products);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });
    };

    updateProducts =(products)=> {
        let list = products.filter(p => p.hasOwnProperty("displayAttrs") && p.displayAttrs.hasOwnProperty("en") && (p.displayAttrs.en.hasOwnProperty("productImage")));
        ls.set("products",list);
        let namespace = ls.get("namespace") || this.state.namespace;
        this.loadMetaData(namespace);
        this.setState({products: list});
    };

    loadMetaData = (namespace) => {
        let headers = {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        };

        axios.get(process.env.REACT_APP_API_BASE_URL+"concept/"+ namespace+ "/stores", {'headers':headers})
        .then(result => {
            this.updateAddress(result)
        }).catch(function (error){
        })
    };

    updateAddress = (result) => {
        var s = '';
        let response = result.data[ls.get("name")];
        if (response.address1 !== undefined) {
            s = "<b>" + response.address1 + "</b>";
        }
        if (response.address2 !== undefined) {
            s = s + "<br/>" + response.address2;
        }
        if (response.address3 !== undefined) {
            s = s + "<br/>" + response.address3;
        }
        this.setState({address:s});
        ls.set("address",s);
        this.props.cookies.set('address', s);
        let planogram  = response.planogramName;
        if(!planogram){
            this.loadPlangogram()
        }else{
            this.loadAssortmentProducts(ls.get("namespace"),planogram)
        }
    };

    loadPlangogram = ()=>{
        axios.get(process.env.REACT_APP_API_BASE_URL+"planogram/data/?storeName="+ls.get("name")+"&namespace="+ls.get("namespace") ).then(response => {
            let namespace = ls.get("namespace");
            let planogram = response.data.planogramName;
            this.loadAssortmentProducts(namespace,planogram);
        })
    };

    loadAssortmentProducts = (namespace,planogram)=> {
        axios.get(process.env.REACT_APP_API_BASE_URL+"assortment/products/?namespace="+namespace+"&planogram="+planogram).then(response => {
            ls.set("assortmentList",response.data);
            let totalProducts = ls.get("products");
            let filterProducts = ls.get("assortmentList");

            let list = totalProducts.filter(function(item) {
                return filterProducts.indexOf(item.prodId) !== -1;
            });
            ls.set("products",list);
            this.setState({products: list});
        })
    };

    componentWillUnmount() {
        this.cancelTokenSource.cancel();
    }

    render() {
        const showSplash = !(this.props.cookies.cookies.isSplashLoaded == 'true');

        return (
            <>
            <div>
                {showSplash &&
                    <SplashPage
                        loadSplashScreen={this.loadSplashScreen}
                        cookies={this.props.cookies}
                    />
                }

                <AppBar className={"appbar"} position="static" color={'transparent'}>
                    <Toolbar>
                        <div className="header_logo">
                            <Link to={"/"}>
                                <img
                                    src={Logo}
                                />
                            </Link>
                        </div>
                        <div className={"drop_div"}>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" className={"drop_btn"}>
                                    {this.state.storeId}
                                    <ArrowDropDownIcon/>
                                </Dropdown.Toggle >

                                <Dropdown.Menu className={"menu_div"}>
                                    <Dropdown.Item className={"wrapper"}>
                                        <div
                                            className={"address"}
                                            dangerouslySetInnerHTML={{__html: this.state.address}}
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/"} className={"change_store"}>
                                        Change store
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="search">
                            <Link to={"/v1/search"} className={"search_btn"}>
                                {/* color="#ABABAB" */}
                                <IconButton >
                                    <SearchIcon/>
                                </IconButton>
                            </Link>
                        </div>
                    </Toolbar>
                </AppBar>

                {(this.state.products.length === 0) ? "" : (
                    this.state.products.map((product, index) => (
                        <ProductTile
                            key={index}
                            product={product}
                            storeId={this.props.storeId}
                            cookies={this.props.cookies}
                        />)
                    )
                )}
            </div>
            <div className="footer">
                <div className="logo">
                    Powered by <img className="logo_image" src={LogoSwyft} />
                </div>
            </div>
            </>
        );
    }
}

export default withStyles(useStyles)(GetProducts);