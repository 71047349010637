import React from "react";
import SearchAppBar from "../../../Atoms/SearchBar/Component";
import axios from "axios";
import ProductTile from "../ProductPage/ProductTile";
import ls from 'local-storage'

class SearchPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search: "",
            products: ls.get("products"),
            searchProds: [],
            hideButton: true
        }
    }

    handleChange = (val) => {
        if (val.length != 0) {
            this.state.hideButton = false;
        } else {
            this.state.hideButton = true;
        }

        val = val.toLowerCase();
        this.setState({search: val});
        this.getProducts();
    };

    clearSearch = () => {
        this.handleChange('');
    };

    getProducts = async() => {
        let products = this.state.products;
        let searchProducts = [];
        if(this.state.products.length != 0){
            for (var i in products) {
                if (products[i].name.toLowerCase().includes(this.state.search)) {
                    searchProducts.push(products[i]);
                }
            }
    
            this.setState({searchProds: searchProducts});
            return;
        }

        await axios.get(process.env.REACT_APP_API_ENDPOINT + ls.get("name") + "/products")
            .then(response => {
                products = response.data;
                this.setState({products: products})
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }

    componentDidMount() {
        this.getProducts();
    }

    render() {
        return(
          <div>
            <SearchAppBar
                search={this.state.search}
                handleChange={this.handleChange}
                clearSearch={this.clearSearch}
                hideButton={this.state.hideButton}
            />
              <div>
                  {(this.state.searchProds.length === 0) ? "" : (
                      this.state.searchProds.map((product, index) => (
                          <ProductTile
                              key={index}
                              // name={product.name}
                              // price={product.price}
                              // imgurl={product.displayAttrs.en.thumbnail.url}
                              product={product}
                              storeId={this.props.storeId}
                              cookies={this.props.cookies}
                          />

                      ))
                  )}
              </div>
          </div>
        );
    }
}

export default SearchPage;
