import React from "react";
import GreySpanButton from "../../../../../Atoms/GreySpanButton/Component";
import {Link} from "react-router-dom";
import OrangeSpanButton from "../../../../../Atoms/OrangeSpanButton/Component";
import "../styles.css";
import ls from 'local-storage'

const WithReceipt = (props) => {
    const emailVal = validateEmail(props.cookies.cookies.email || props.val);

    let data = {
        storeId: ls.get("name"),
        product: ls.get("product"),
        email: props.val
    };

    function validateEmail(value) {
        const emailRegExp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        return emailRegExp.test(value);
    }

    const ConditionalLink = (props) => {
        return (props.checked && emailVal) ?
            (<Link
                to={{pathname: "/v1/verify-code", "data": data}}
                className={"checkout_btn orange_btn"}>
                    <OrangeSpanButton onClick={props.submit}>CHECKOUT WITH RECEIPT</OrangeSpanButton>
             </Link>)
        :
        (<OrangeSpanButton onClick={props.submit}>CHECKOUT WITH RECEIPT</OrangeSpanButton>);
    };

    return(
        <div className={"receipt_div_buttons with_receipt"}>
            <div style={divStyle}>
                <ConditionalLink checked={props.checked} submit={props.submit}/>
            </div>
            <div style={divStyle}>
                <Link to={"/v1/verify-code"}>
                    <OrangeSpanButton
                        className={'without_receipt_check'}
                        onClick={props.submit}>Want to checkout without a receipt?
                    </OrangeSpanButton>
                </Link>
            </div>
        </div>
    );
}

export default WithReceipt;

const divStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
}