import React from 'react';
import PinInput from "react-pin-input";
import AppBarplain from "../../../Atoms/AppbarPlain/Component";
import {Container, Row, Col} from 'react-bootstrap';
import './styles.css';
import { Button, SvgIcon } from '@material-ui/core';
import firebase from './../../../firebase'
import { withRouter } from 'react-router-dom';
import FindCodeModal from "./FindCode/Component";
import ls from 'local-storage'
import appAnalytics from '../../../AppAnalytics';

class CodePage extends React.Component {

    validPin = {
        color: "#3CBDE2",
        fontWeight: "500",
        fontSize: "30px",
        border: "0.5px solid #E7E7E7",
        borderRadius: "3px",
        background: "#F5F5F5",
        width: "45px",
        height: "60px"
    };

    inValidPin = {
        color: "#3CBDE2",
        fontWeight: "500",
        fontSize: "30px",
        border: "0.5px solid #CC0000",
        borderRadius: "3px",
        background: "#F5F5F5",
        width: "45px",
        height: "60px"
    };

    constructor(props) {
        super(props)
        
        this.state = {
            value: "",
            isError: false,
            storeId: ls.get("name"),
            address: '',
            email: this.props.cookies.get("email") ? this.props.cookies.get("email") : "praveen.konathala@zoomsystems.com",
            product:ls.get("product"),
            isOTPVerified: false,
            isShowModal: false
        };

        this.db = firebase.firestore();
        appAnalytics.customLogEvent("Code Page",{
            content_type: 'code_page',
            content_id: 'code_page',
            items: []
        })
    }

    componentDidMount(){
      this.getAuthCodeFromDb()
    }

    componentWillUnmount(){
    }

    onChange = value => {
        console.log("authcode === " + this.authCode);

        //remove error state if user is editting
        this.setState({isError:false});

        if (value.length < 4 ){
            this.getAuthCodeFromDb()
        } else if (value.length === 4 && value == this.authCode) {
            this.navigateToFinalPage(value)
        } else if (value.length === 4 && value != this.authCode) {
            this.setState({isError:true})
        }
    };

    navigateToFinalPage(code){
        if (this.authCode == code && !this.cpQueueStatus) {
            this.props.cookies.set('value', this.authCode);
            this.setState({isOTPVerified:true});
            this.props.history.push("/v1/sending-order");
        } else {
           this.setState({isError:true});
        }
    }

    setModalShow(isBool){
        this.setState({isShowModal:isBool})
    }

    authCode = '';
    cpQueueStatus = false;

    getAuthCodeFromDb() {
        this.db.collection("contactless").doc(this.state.storeId).collection("status").doc("authCode").get().then(doc => {
            let data = doc.data();
            if(data.hasOwnProperty("AccessCode")){
                this.authCode = doc.data()["AccessCode"];
            }
           
        });

        this.db.collection("contactless").doc(this.state.storeId).collection("status").doc("cpQueue").get().then(doc => {
            let data = doc.data();
            if(data.hasOwnProperty("status")){
                this.cpQueueStatus = doc.data()["status"];
            }else{
                this.cpQueueStatus = false  
            }
            
        });
        this.db.collection("contactless").doc(this.state.storeId).collection("status").doc("isShowAuthCode").set({"isShowAuthCode": true});
    }

    render(){
        return (
            <div>
                <AppBarplain/>

                <Container fluid className={'enter_code'}>
                    <Row noGutters={true} className={"main-row"} >
                        <Col><p className={"title"}>Verification Required</p></Col>
                    </Row>

                    <Row noGutters={true} className={"desc"}>
                        <Col><h1 className={"desc-label"}>Please enter the one-time verification code displayed on the store screen</h1></Col>
                    </Row>

                    <Row noGutters={true} className={"pin-view"}>
                        <Col>
                            <PinInput
                                length={4}
                                focus
                                ref={p=> (this.pin = p)}
                                inputStyle={this.state.isError ? this.inValidPin : this.validPin}
                                type="numeric"
                                inputMode="number"
                                onChange={this.onChange}
                            />
                        </Col>
                    </Row>

                    {this.state.isError ?
                        <Row className={"error-row"}>
                            <Col>
                                <Button
                                    className={"error-btn"}
                                    startIcon={<SvgIcon>
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                                    </SvgIcon> }
                                >
                                    Invalid entry. Please try again.
                                </Button>

                                {this.cpQueueStatus &&
                                    <Button className={"error-btn"}>Or wait for your turn</Button>
                                }
                            </Col>
                        </Row>
                        : null
                    }

                    <Row className={"footer"}>
                        <Col>
                            <Button
                                onClick={() => this.setModalShow(true)}
                                className={"modal_btn"}>
                                Can't find the code?
                            </Button>
                        </Col>

                        <FindCodeModal
                            modalIsopen={this.state.isShowModal}
                            closeModal={() => this.setModalShow(false)}>
                        </FindCodeModal>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default withRouter(CodePage);