import React from "react";
import OrangeSpanButton from "../../../../../Atoms/OrangeSpanButton/Component";
import {Link} from "react-router-dom";
import "../styles.css";

const WithoutReceipt = (props) => {
    const ConditionalLink = (props) => {
        return props.checked ? (
            <Link to={"/v1/verify-code"} className={"checkout_btn orange_btn"}>
                <OrangeSpanButton onClick={props.submit}>CHECKOUT WITHOUT RECEIPT</OrangeSpanButton>
            </Link>)
            :
            (<OrangeSpanButton onClick={props.submit}>CHECKOUT WITHOUT RECEIPT</OrangeSpanButton>);
    };

    return(
        <div className={"receipt_div_buttons without_receipt"}>
            <div style={divStyle}>
                <ConditionalLink checked={props.checked} submit={props.submit}/>
            </div>
        </div>
    );
};

export default WithoutReceipt;

const divStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
}