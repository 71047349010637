import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ScrollToTop from './Atoms/ScrollToTop/Component';
import * as serviceWorker from './serviceWorker';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import {BrowserRouter as Router} from "react-router-dom";
import {createStore} from "redux";
import {Provider} from "react-redux";
import {CookiesProvider} from "react-cookie";
import firebase from 'firebase';
import appAnalytics from './AppAnalytics';

const initialState = {
    count: 0
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case "INCREMENT" :
            return {
                count: state.count + 1
            };
        case "DECREMENT" :
            return {
                count: state.count - 1
            };
        default:
            return state;
    }
}

const store = createStore(reducer);
const analytics = firebase.analytics();
appAnalytics.customLogEvent("Application_initialization",{content_type: 'app_start',
content_id: 'app_start',
items: []})

// store.dispatch({type: "INCREMENT"});
// store.dispatch({type: "DECREMENT"});

// ReactDOM.render(
//     <Provider store={store}>
//         <Counter count={42}/>
//     </Provider>,
//     document.getElementById("root")
// );

ReactDOM.render(
    <CookiesProvider>
        <Router>
            <ScrollToTop>
                <Provider store={store}>
                    <App/>
                </Provider>
            </ScrollToTop>
        </Router>
    </CookiesProvider>,
    document.getElementById("root")
);

serviceWorker.unregister();

