import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {withCookies} from "react-cookie";

import StoreChoose from "./Components/Pages/StoreChoose/Component";
import GetProducts from "./Components/Pages/ProductPage/Component";
import ProductDetail from "./Components/Pages/ProductDetail/Component";
import SearchPage from "./Components/Pages/SearchPage/Component";
import CartPage from "./Components/Pages/CartPage/Component";
import SendingPage from "./Components/Pages/SendingPage";
import CodePage from "./Components/Pages/CodePage";

class App extends React.Component {
    render() {
        return (
            <div className="App">
                <Switch>
                    <Route exact={true} path="/" render={() => (<StoreChoose cookies={this.props.cookies}/>)}></Route>
                    <Route exact={true} path="/:id" render={() => (<GetProducts cookies={this.props.cookies}/>)}></Route>
                    <Route exact={true} path="/v1/product-page" render={() => (<GetProducts cookies={this.props.cookies}/>)}/>
                    <Route exact={true} path="/v1/search" render={() => (<SearchPage cookies={this.props.cookies}/>)}/>
                    <Route exact={true} path='/v1/your-order' render={() => (<CartPage cookies={this.props.cookies}/>)}/>
                    <Route exact={true} path="/v1/product-detail" render={() => (<ProductDetail cookies={this.props.cookies}/>)}/>
                    <Route exact={true} path="/v1/sending-order" render={() => (<SendingPage cookies={this.props.cookies}/>)}/>
                    <Route exact={true} path="/v1/verify-code" render={() => (<CodePage cookies={this.props.cookies}/>)}/>
                </Switch>
            </div>
        );
    }
}

export default withCookies(App);
