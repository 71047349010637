import React from "react";
import "./styles.css";
import Logo from "../../../../images/logo.png";
import LogoSwyft from "../../../../images/logo-swyft.png";

function SplashPage(props) {

    const [counter, setCounter] = React.useState(5);

    React.useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        if (counter === 0) {
            props.cookies.set("loading", false);
            props.loadSplashScreen(false);
            props.cookies.set("isSplashLoaded", true);
        }
    }, [counter]);

    return (
        <div className="splashPage">
            <div>
                <div>
                    <img
                        className={"prod_image"}
                        src={Logo}
                    />
                </div>

                Loading Products
                <span>Please wait</span>

                <div className="progress">
                    <div className="progress-value"></div>
                </div>
            </div>

            <div className="footer">
                <div className="logo">
                    Powered by <img className="logo_image" src={LogoSwyft} />
                </div>
            </div>
        </div>
    );
}

export default SplashPage;