import React from "react";
import Modal from 'react-modal';
import ModalHeader from "react-bootstrap/ModalHeader";
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import HelpImage from "../../../../images/code-help.svg";

export default class FindCodeModal extends React.Component {
    render() {
        return (
            <Modal
                isOpen={this.props.modalIsopen}
                onRequestClose={this.props.closeModal}
                className={"findCode"}>

                <ModalHeader>
                    <div className={"modalHeader"}>
                        <IconButton color="inherit" onClick={this.props.closeModal}>
                            <ClearIcon/>
                        </IconButton>
                    </div>
                </ModalHeader>

                <div className={"instructions"}>
                    <p>The <b>4 digit</b> code is located at the bottom of the touch-screen on the store.</p>

                    <img
                        src={HelpImage}
                        alt="Find Code Location"
                    />

                    <p>If the store is in use, please wait until it becomes available.</p>

                    <p>The store will generate a new code.</p>
                </div>

                <div className={"modalFooter"}>
                    <button
                        onClick={this.props.closeModal}
                        className={'okBtn'}>
                    OK
                    </button>
                </div>
            </Modal>
        )
    }
}