import firebase from 'firebase'
//Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyDCfaGnEc7RLT9D0b10Rxk1HfJP8nbeT4Y",
    authDomain: "zoom-shops-qa.firebaseapp.com",
    databaseURL: "https://zoom-shops-qa.firebaseio.com",
    projectId: "zoom-shops-qa",
    storageBucket: "zoom-shops-qa.appspot.com",
    messagingSenderId: "68447112870",
    appId: "1:68447112870:web:92c1b2f913b630ee072e67",
    measurementId: "G-Y2YSKGPZZ3"
  };
  // ---prod--
  firebaseConfig = {
    apiKey: "AIzaSyB_Mkl4aSeQONbN9xu7QX7xc0hJ36RKCu4",
    authDomain: "zoom-shops.firebaseapp.com",
    databaseURL: "https://zoom-shops.firebaseio.com",
    projectId: "zoom-shops",
    storageBucket: "zoom-shops.appspot.com",
    messagingSenderId: "840189785516",
    appId: "1:840189785516:web:d66d044111297eabbbb609",
    measurementId: "G-5272RX4Z0P"
  };

  // For Firebase JS SDK v7.20.0 and later, measurementId is optional

  // Initialize Firebase
  console.log("--firebase--"+process.env.isProd)
  console.log("--firebase--"+process.env.firebaseConfig)
  firebase.initializeApp(firebaseConfig);
  export default firebase