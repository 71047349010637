import React from "react";
import Modal from 'react-modal';
import ModalHeader from "react-bootstrap/ModalHeader";
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

Modal.setAppElement('body');

export default class PrivacyPolicyModal extends React.Component {
    render() {
        return (
            <Modal
                isOpen={this.props.modalIsopen}
                onRequestClose={this.props.closeModal}
                className={"privacyPolicy"}>

                <ModalHeader>
                    <div className={"modalHeader"}>
                        <IconButton color="inherit" onClick={this.props.closeModal}>
                            <ClearIcon/>
                        </IconButton>
                    </div>
                </ModalHeader>

                <section>
                    <h2>Privacy Policy</h2>

                    <p className='support'>Swyft, Inc. ("Swyft") is committed to maintaining robust privacy protections for its users. Our Privacy Policy ("Privacy Policy") is designed to help you understand how we collect, use and safeguard the information you provide to us and to assist you in making informed decisions when using our Service.</p>

                    <p className='support'>For purposes of this Privacy Policy, "Service" refers to the purchase, deposit or collection of retail or commercial items for sale or redemption via our automated retail stores ("Swyft Stores"). The terms "we," "us," and "our" refer to Swyft. "You" refers to you, as a user of our Service. "Personal Information" refers to any information that identifies, relates to, describes, is capable of being associated with, or may reasonably be linked, directly or indirectly, with a particular user. Some examples of Personal Information are real names, aliases, IP addresses, email addresses, biometric information, or geolocation data. "Non-Identifiable Information" refers to information that can never be linked to any user and is anonymous. "Service Provider" refers to an entity that processes your information on our behalf. Our Service Providers are prohibited from retaining, using, or disclosing your information for any purpose other than to perform services for us.</p>

                    <p className='support'>We hope you will thoroughly read our Privacy Policy so that you are aware of how and why we are using your data. To make it easier, our Privacy Policy is provided in a layered format:</p>
                        <ul className='support'>
                            <li>Information You Share with Us</li>
                            <li>Other Information We Collect</li>
                            <li>How We Use Your Information</li>
                            <li>Information We Share and Disclose</li>
                            <li>How We Protect Your Information</li>
                            <li>Managing Your Personal Information</li>
                            <li>International Users</li>
                            <li>Children</li>
                            <li>Changes to Our Privacy Policy</li>
                        </ul>

                    <h2>INFORMATION YOU SHARE WITH US</h2>

                    <p className='support'>With your consent through our Swyft Stores, we may collect Personal Information, such as your name, phone number, mailing address, and email address. This enables us to give you the best service and provide you with functionality that benefits you and is common in retail experiences, such as providing you with a receipt via email or providing you with loyalty discounts by identifying you.</p>

                    <p className='support'>If you make a purchase from a Swyft Store, we collect non-PCI DSS protected card data such as the first six and last four digits of your card number and your name. This information is required to perform and complete your purchase transaction. We also collect your email or phone number if you wish to receive a receipt via these methods.</p>

                    <p className='support'>PCI-DSS data we collect is managed by and sent directly to our Service Providers. In some cases, our Service Providers may tokenize that card data to register a uniquely identifiable token (unique user ID) and provide that to us.</p>

                    <p className='support'>Unique user IDs or digital codes that may be used to uniquely identify you may also be collected, such as a membership number to a retail loyalty program. In some cases, you may also submit a scan of your Driver's License, Passport or Identity Card that is used to verify your identity in order for us to deliver a product registered in your name, or complying with local and federal regulations around dispensing regulated products.</p>

                    <p className='support'>In the past twelve months, we collected the following categories of Personal Information from our Swyft Stores:</p>
                        <ul className='support'>
                            <li>Phone numbers</li>
                            <li>Email addresses</li>
                            <li>Unique User IDs</li>
                            <li>Scans of identity records such as Driver's Licenses or Passports</li>
                            <li>Non-PCI DSS protected card data</li>
                        </ul>

                    <p className='support'>Sometimes you may wish to provide Personal Information to our brand partners when using the Swyft Store. For example, our brand partner's Swyft Stores may allow you to enter a phone number, email address, or rewards number to use coupons, receive discounts and accumulate loyalty credits. If you provide any Personal information to a brand partner via our Swyft Store, that brand partner's own privacy policy will govern the use, handling, and disclosure of your Personal Information once it is shared. If you want to learn more about a brand partner's privacy practices, we encourage you to read through their privacy policy via our Swyft Store interface before providing your consent.</p>

                    <h2>OTHER INFORMATION WE COLLECT</h2>

                    <p className='support'>We use demographic analysis software in our Swyft Stores to capture Non-Identifiable Information in order to improve the quality of our Service. We use a camera, which does not record any imagery, but instead temporarily transfers pixels to a volatile memory (RAM) for a period of between 66 and 200 milliseconds. The pixels are compared against a database and then completely erased from volatile memory. We do not capture, convert, store, or share any Personal Information through this process. The resulting data that is captured, converted, stored or shared is only aggregated data that is completely anonymous.</p>

                    <p className='support'>In some rare cases, we use security cameras in our Swyft Stores. Where security cameras are recording constant streaming video, cameras will be visible and accompanied by a clear notice advising you that security surveillance video recording is in progress.</p>

                    <p className='support'>The Swyft Store also automatically records Non-Identifiable Information such as:</p>
                        <ul className='support'>
                            <li>Time</li>
                            <li>Date of Use</li>
                            <li>Interaction Data</li>
                            <li>Transaction Information</li>
                        </ul>

                    <h2>HOW WE USE YOUR INFORMATION</h2>

                    <p className='support'>In general, Personal Information you provide to us is used to complete purchase transactions in the Swyft Store. With your consent, we may also use your Personal Information to, for example:</p>
                        <ul className='support'>
                            <li>Manage your account and provide you with customer support</li>
                            <li>Communicate with you by email, postal mail, telephone, and/or mobile devices about products or services that may be of interest to you</li>
                            <li>Personalize your experience</li>
                            <li>Improve the quality of our service</li>
                            <li>Administer a contest, promotion, survey, or other feature</li>
                            <li>Provide technical support</li>
                        </ul>

                    <p className='support'>We also engage with several Service Providers to process your information on our behalf so that we can provide you the best user experience, as well as for our internal purposes. These Service Providers allow us to, for example:</p>
                        <ul className='support'>
                            <li>Connect you with the proper department when you contact us</li>
                            <li>Manage PCI-DSS data</li>
                            <li>Communicate with you via email</li>
                            <li>Securely process your payment information</li>
                            <li>Aggregate Non-Identifiable Information to improve our service</li>
                        </ul>

                    <p className='support'>Any Personal Information we share with such Service Providers use your Personal Information only at our direction and in accordance with our Privacy Policy.</p>

                    <p className='support'>We use Non-Identifiable Information to help us improve our Service. We also aggregate anonymous Non-Identifiable Information in order to track trends and analyze use patterns at our Swyft Stores.</p>

                    <p className='support'>We will only retain your information for as long as necessary to fulfill the purposes we collected it for, including to satisfy any legal, accounting, or reporting requirements. We consider the amount, nature, and sensitivity of your Personal Information, the potential risk associated with an unauthorized disclosure of your Personal Information, and our purposes for processing your Personal Information when assessing the appropriate retention period for your information.</p>

                    <p className='support'>We may share information with New Zoom, LLC. ("ZoomSystems"), our subsidiary company, in order to provide innovative and consistent experiences across our entire network. We also may process information about you between us and ZoomSystems for these purposes, as permitted by applicable law.</p>

                    <h2>INFORMATION WE SHARE AND DISCLOSE</h2>

                    <p className='support'>Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share your Personal Information with third parties without your consent. We may preserve, use, or disclose your Personal Information as necessary to comply with law, regulation, legal process, or governmental request.</p>

                    <p className='support'>This Privacy Policy does not limit in any way our use or disclosure of Non-Identifiable Information. We reserve the right to use and disclose such Non-Identifiable Information to our partners, advertisers and other third parties at our discretion.</p>

                    <p className='support'>In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your Personal Information may be among the assets transferred.</p>

                    <h2>HOW WE PROTECT YOUR INFORMATION</h2>

                    <p className='support'>We implement security measures designed to protect your information from unauthorized access in compliance with the European Union's General Data Protection Regulation. These security measures include:</p>
                        <ul className='support'>
                            <li>Encryption</li>
                            <li>Firewalls</li>
                            <li>Secure socket layer technology</li>
                            <li>PCI DSS compliance for financial information</li>
                        </ul>

                    <p className='support'>In the event that Personal Information is compromised as a result of a breach of security, we will promptly notify you if we deem the breach material, or as otherwise required by law.</p>

                    <h2>MANAGING YOUR PERSONAL INFORMATION</h2>

                    <p className='support'>You may opt-out of future messages from us by sending an unsubscribe request to us at privacy@SwyftStore.com.</p>

                    <p className='support'>You may request and obtain from us twice a year, free of charge, upon the receipt of a confirmation of identity:</p>
                        <ul className='support'>
                            <li>Categories of Personal Information collected about you</li>
                            <li>Categories of sources from which that Personal Information is collected</li>
                            <li>Our purposes for collecting your Personal Information</li>
                            <li>Categories of third parties with whom we share your Personal Information</li>
                            <li>Specific pieces of Personal Information that we have collected about you</li>
                            <li>We are not required to reidentify or link Non-Identifiable Information.</li>
                        </ul>

                    <p className='support'>If the Personal Information you provided to us is incorrect, you may request we fix it for you. You may request and obtain from us the rectification of inaccurate Personal Information by providing a supplementary statement to our privacy team.</p>

                    <p className='support'>You may request your Personal Information be deleted, unless:</p>
                        <ul className='support'>
                            <li>Your Personal Information is required to complete a transaction</li>
                            <li>Your Personal Information is used to detect and protect against security incidents</li>
                            <li>Your Personal Information is used to identify and repair errors that impair Swyft Store functionality</li>
                            <li>Your Personal Information is used for our internal uses</li>
                            <li>Non-deletion is required by law</li>
                        </ul>

                    <p className='support'>You may also request that we stop processing your Personal Information if:</p>
                        <ul className='support'>
                            <li>It is inaccurate</li>
                            <li>We no longer need it</li>
                            <li>You want us to stop but do not want your Personal Information deleted</li>
                            <li>You have provided relevant and reasoned objection to your local supervisory authority if you are a resident of the European Union</li>
                        </ul>

                    <p className='support'>If you are a resident of the European Union, you have a right to lodge a complaint with a local supervisory authority when you have any objection to our processing of your Personal Information. However, we would appreciate the opportunity to first address your concerns—so please contact us first!</p>

                    <p className='support'>We will never discriminate against you for exercising any of your rights under this Privacy Policy.</p>

                    <p className='support'>Our privacy team is responsible for your Personal information. You may contact our privacy team by:</p>

                    <p><b>Mail</b><br/>
                        Swyft Privacy Team<br/>
                        140 Geary St, 7th Floor<br/>
                        San Francisco, CA 94108</p>
                    <p><b>Phone</b><br/>
                        +1 415-766-2882</p>
                    <p><b>Email</b><br/>
                        privacy@SwyftStore.com</p>

                    <h2>INTERNATIONAL USERS</h2>

                    <p className='support'>Our business is operated in the United States. By providing us with your Personal Information, you acknowledge and agree that your Personal Information may be processed for the purposes identified in this policy. You freely and specifically give us your consent to export and use your information within the United States. You understand Personal Information stored in the United States may be subject to lawful requests by the courts or law enforcement authorities in the United States. If you are located in the European Union, whenever we transfer your Personal Information to Service Providers outside of the European Union, we ensure appropriate safeguards are used in such transfer.</p>

                    <h2>CHILDREN</h2>

                    <p className='support'>Protecting the privacy of children is especially important to us. For that reason, we do not knowingly collect or solicit Personal Information from individuals under the age of 13. If you are under 13, please do not send any information about yourself to us, including your name, address, telephone number, or email address. No one under the age of 13 is allowed to provide any Personal Information to us. If you are over 13, you must be old enough to consent to the processing of Personal Information in your country.</p>

                    <p className='support'>In the event that we learn we have collected Personal Information from an individual under the age of 13 or under the age of consent to processing without verification of parental consent, we will delete that information as quickly as practicable. If you believe we might have any information from or about an individual under the age of 13, or who is unable to legally consent in his or her country, please contact the Data Controller.</p>

                    <h2>CHANGES TO OUR PRIVACY POLICY</h2>

                    <p className='support'>We may revise this Privacy Policy from time to time. The most current version of this Privacy Policy can be found at https://swyftstore.com/privacy-policy/. If we make any changes that we deem material, we will notify you via the email address you provided to us. By continuing to use Swyft's Services and the Swyft Store, after any changes become effective, you agree to be bound by our updated Privacy Policy.</p>

                   <button
                        onClick={this.props.closeModal}
                        className={'okBtn'}>
                    OK
                    </button>

                </section>
            </Modal>
        );
    }
}