import firebase from 'firebase'


 class AppAnalytics {

    logEvent(){
        firebase.analytics().logEvent('select_content', {
            content_type: 'text',
            content_id: 'P12453',
            items: [{ name: 'testitem' }]
          });
    }

    customLogEvent(eventname,body) {
        firebase.analytics().logEvent(eventname, body);
    }

}

let appAnalytics = new AppAnalytics()

export default appAnalytics;