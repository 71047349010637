import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from "@material-ui/icons/Search";
import classNames from 'classnames';
import "./styles.css";

class Searchbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: ''
        };
    }

    handleChange(event) {
        this.setState({value: event.target.value},
            () => {console.log(this.state.value);});
    }

    clearSearch() {
        this.setState({
            value: ''
        });
    }

    render() {
        const closeBtn = classNames({
          'hide': this.props.hideButton,
          'close_btn': true
        });

        return (
            <div className={"search_div"}>
                <div className={"search_bar"}>
                    <div className={"search_icon"}>
                        <SearchIcon/>
                    </div>
                    <InputBase
                        className={"search_input"}
                        placeholder="Search Products"
                        value={this.props.search}
                        onChange={(event => {this.props.handleChange(event.target.value)})}
                    />
                    <IconButton onClick={this.props.clearSearch}>
                        <ClearIcon className={closeBtn}/>
                    </IconButton>
                </div>
            </div>
        );
    }
}

export default Searchbar;
