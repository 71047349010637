import styled from "styled-components";

const OrangeSpanButton = styled.button`
    background-color: #3CBDE2;
    color: white;
    height: 36px;
    width: 100%;
    align-items: center;
    border: none;
    font-size: 14px;
`;

// class OrangeSpanButton extends React.Component{
//     render() {

// const OrangeSpanButton = (props) => {
//         return (
//             // <div style={divStyle}>
//             <CustomButton>{props.label}</CustomButton>
//             // </div>
//         );
//     // }
// }
//
// export default OrangeSpanButton;

export default OrangeSpanButton;
