import React from "react";
import Modal from 'react-modal';
import ModalHeader from "react-bootstrap/ModalHeader";
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

export default class NeedHelpModal extends React.Component {
    render() {
        return (
            <Modal
                isOpen={this.props.modalIsopen}
                onRequestClose={this.props.closeModal}
                className={"needHelp"}>

                <ModalHeader>
                    <div className={"modalHeader"}>
                        <IconButton color="inherit" onClick={this.props.closeModal}>
                            <ClearIcon/>
                        </IconButton>
                    </div>
                </ModalHeader>

                <div className={"instructions"}>
                    <p className={'title'}>Verification and payment instructions</p>

                    <p className={'question'}>I can't see my order on the screen?</p>
                    <p>Please make sure you are standing at the correct store and your code matches the one on the screen.</p>

                    <p className={'question'}>What happens now?</p>
                    <ol>
                        <li>Check your access code on the store screen and make sure the products match the ones you ordered. Don't worry, you don't need to touch it.</li>
                        <li>Make a payment using a contactless payment method.</li>
                        <li>The store will dispense your products. Collect them without touching the door.</li>
                        <li>You're good to go. The store will reset for the next customer once you've retrieved all your purchases.</li>
                    </ol>

                    <p className={'question'}>I don't have acontactless payment method</p>
                    <p>Please try swiping your payment card without touching the payment device.</p>
                </div>

                <div className={"modalFooter"}>
                    <p>For other questions, contact our Support Team:<br/>
                    1-800-XXX-XXXX</p>

                    <button
                        onClick={this.props.closeModal}
                        className={'okBtn'}>
                    OK
                    </button>
                </div>
            </Modal>
        );
    }
}