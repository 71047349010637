import React from "react";
import Modal from 'react-modal';
import ModalHeader from "react-bootstrap/ModalHeader";
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import StoreImage from "../../../../images/storeID.svg";

export default class FindNumberModal extends React.Component {
    render() {
        return (
            <Modal
                isOpen={this.props.modalIsopen}
                onRequestClose={this.props.closeModal}
                className={"findNumber"}>

                <ModalHeader>
                    <div className={"modalHeader"}>
                        <IconButton color="inherit" onClick={this.props.closeModal}>
                            <ClearIcon/>
                        </IconButton>
                    </div>
                </ModalHeader>

                <div className={"instructions"}>
                    <p className={'title'}>How to locate the Store ID</p>

                    <p>A <b>Store ID</b> will appear on the touch screen when it is available.</p>

                    <img
                        src={StoreImage}
                        alt="Store Code Location"
                    />

                    <p>Enter the <b>Store ID</b> on this app to start shopping.</p>
                </div>

                <div className={"modalFooter"}>
                    <button
                        onClick={this.props.closeModal}
                        className={'okBtn'}>
                    OK
                    </button>
                </div>
            </Modal>
        );
    }
}